import React, {useState} from 'react';
import {useTeam} from "../../contexts/TeamContext";
import Select from "react-select";

function FixtureForm({isCreating, setIsCreating, handleSubmit, fixture, setFixture}) {
    const formatDate = (date) => {
        const d = new Date(date);
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        const year = d.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const [against, setAgainst] = useState(fixture ? fixture.against : '');
    const [date, setDate] = useState(fixture ? (formatDate(fixture.date)) :'');
    const [againstScore, setAgainstScore] = useState(fixture ? fixture.againstScore : 0);
    const [isPastFixture, setIsPastFixture] = useState(() => {
        if (fixture) {
            const fixtureDate = new Date(fixture.date);
            const today = new Date();
            return fixtureDate < today;
        }
        return false;
    });
    const [isAddingGoal, setIsAddingGoal] = useState(false);
    const [newGoal, setNewGoal] = useState({scorer: {}, assister: {}, minute: null});
    const {team} = useTeam();

    const handleDateChange = (e) => {
        const selectedDate = e.target.value;
        const today = new Date();
        const selectedDateObj = new Date(selectedDate);

        setDate(selectedDate); // Save the date as a string

        // Check if the selected date is in the past
        if (selectedDateObj < today) {
            setIsPastFixture(true);
        } else {
            setIsPastFixture(false);
            setFixture({...fixture, goals: []}); // Clear goals if the fixture date is not in the past
            setAgainstScore(0); // Reset against score if the fixture date is not in the past
        }
    };

    const handleAddGoal = () => {
        setFixture({...fixture, goals: [...fixture.goals, newGoal]})
        setNewGoal({scorer: {}, assister: {}, minute: null});
        setIsAddingGoal(false);
    };

    return (
        <div className={`modal ${isCreating ? 'show' : ''}`} style={{ display: isCreating ? 'block' : 'none' }} tabIndex="-1">
            <div className="modal-dialog">
                <div className="modal-content" style={{
                    backgroundColor: team.theme.primaryColor,
                    border: "solid",
                    borderRadius: '20px',
                    borderWidth: "medium",
                    borderColor: team.theme.secondaryColor,
                    zIndex: 10
                }}>
                    <div className="modal-header">
                        <h5 className="modal-title">Add Fixture</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close" onClick={() => setIsCreating(false)}></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="d-flex mb-1">
                                <div className={'col-7'}>
                                    <label>Against:</label>
                                    <input
                                        type="text"
                                        value={against}
                                        onChange={(e) => setAgainst(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className={'col-4'}>
                                    <label>Date:</label>
                                    <input
                                        type="date"
                                        value={date}
                                        onChange={handleDateChange}
                                        required
                                    />
                                </div>
                            </div>

                            {isPastFixture && (
                                <>
                                    <div className={'scoreboard d-flex flex-row'}>
                                        <div className="homeScore col-6 d-flex flex-column align-items-center">
                                            <label>Team Score:</label>
                                            <h1 className={'mx-2'}>{fixture.goals.length}</h1>
                                        </div>
                                        <div className="oppoScore col-6 d-flex flex-column align-items-center">
                                            <label>Opposition Score:</label>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <i className="bi bi-dash-lg" onClick={() => {setAgainstScore(againstScore-1)}}></i>
                                                <h1 className={'mx-2'}>{againstScore}</h1>
                                                <i className="bi bi-plus-lg" onClick={() => {setAgainstScore(againstScore+1)}}></i>
                                            </div>


                                        </div>

                                    </div>

                                    <div className={'goals'}>
                                        <label>Goals:</label>
                                        {fixture.goals.length > 0 && (
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th scope="col">Scorer</th>
                                                    <th scope="col">Assister</th>
                                                    <th scope="col">Minute</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {fixture.goals.map((goal, index) => (
                                                    <tr key={index}>
                                                        <td>{goal.scorer.user.displayName}</td>
                                                        <td>
                                                            {/*<img src={user.avatarUrl} alt="User Image"*/}
                                                            {/*     style={{maxHeight: '50px'}}/>*/}
                                                            {goal.assister.user ? (goal.assister.user.displayName) : ('None')}
                                                        </td>
                                                        <td>{goal.minute}'</td>
                                                    </tr>
                                                    // <div className={'d-flex flex-row justify-content-between'} key={index}>
                                                    //     <div>
                                                    //         {(goal.assister.user) ? (
                                                    //              + ' ' + goal.assister.user.displayName + ' ' + goal.minute + `'`
                                                    //         ) : (goal.scorer.user.displayName + ' No assister ' + goal.minute + `'`)
                                                    //         }
                                                    //     </div>
                                                    //     <i className="bi bi-x-lg" onClick={() => {
                                                    //         setGoals(goals.filter((_, i) => i !== index));
                                                    //     }}></i>
                                                    // </div>
                                                ))}
                                                </tbody>
                                            </table>
                                        )}

                                        {isAddingGoal && (
                                            <div className={'goal-input d-flex flex-row'}>
                                                <Select
                                                    className={'col-5'}
                                                    options={team.players}
                                                    formatOptionLabel={player => (
                                                        <div className="player-option">
                                                            <img src={player.user.avatarUrl} alt="player-image" style={{
                                                                width: '50px',
                                                                height: '50px',
                                                                marginRight: '10px'
                                                            }}/>
                                                            <span>{player.user.displayName}</span>
                                                        </div>
                                                    )}
                                                    isSearchable={false}
                                                    onChange={(player) => setNewGoal({...newGoal, scorer: player})}
                                                    placeholder="Scorer"

                                                />
                                                <Select
                                                    className={'col-5'}
                                                    options={team.players}
                                                    formatOptionLabel={player => (
                                                        <div className="player-option">
                                                            <img src={player.user.avatarUrl} alt="player-image" style={{
                                                                width: '50px',
                                                                height: '50px',
                                                                marginRight: '10px'
                                                            }}/>
                                                            <span>{player.user.displayName}</span>
                                                        </div>
                                                    )}
                                                    isSearchable={false}
                                                    onChange={(player) => setNewGoal({...newGoal, assister: player})}
                                                    placeholder="Assister"
                                                />
                                                <div className="col-2 d-flex flex-column align-items-center">
                                                    <input
                                                        type="number"
                                                        placeholder="Min'"
                                                        value={newGoal.minute}
                                                        onChange={(e) => {
                                                            setNewGoal({...newGoal, minute: e.target.value})
                                                        }}
                                                        className={'mw-100'}
                                                    />
                                                    <div>
                                                        Add<i className="bi bi-check" onClick={handleAddGoal}></i>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {!isAddingGoal && (
                                            <button onClick={() => {
                                                setIsAddingGoal(true)
                                            }}>Add goal</button>
                                        )}
                                    </div>
                                </>
                            )}
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                                onClick={() => setIsCreating(false)}>Close
                        </button>
                        <button type="button" className="btn btn-primary" onClick={handleSubmit}>Save changes</button>
                    </div>
                </div>
            </div>
            {isCreating && <div className="modal-backdrop fade show" style={{zIndex: 5}}></div>}
        </div>
    );
}

export default FixtureForm;