import React from 'react';
import Carousel from "../components/Carousel";

function Fixtures(props) {
    return (
        <div className="fixtures p-2">
            <h1 className="title">Fixtures</h1>
            <Carousel/>
        </div>
    );
}

export default Fixtures;