import React, {createContext, useState, useEffect, useContext} from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const apiURL = process.env.REACT_APP_API_URL;

    const fullBodyBitmojiIds = {
        standingWithBall: "10141259",
        keepUp: "10141213",
        bicycleKick: "10236529"
    }

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await fetch(`${apiURL}/api/user`, {
                    credentials: 'include'
                });

                if (response.ok) {
                    const data = await response.json();
                    setUser(data);
                    console.log('set user to ' + JSON.stringify(data));
                } else {
                    console.log("Error:", response.statusText);
                }
            } catch (error) {
                console.error("Fetch error:", error);
            }
        };
        fetchUser();
    }, []);


    return (
        <UserContext.Provider value={{user, setUser, fullBodyBitmojiIds}}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);