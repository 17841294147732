import React from 'react';

function FixturePreview({fixture , setFixEditing, setIsEditing}) {
    const fixtureDate = new Date(fixture.date);
    const day = fixtureDate.toLocaleDateString('en-GB', { day: 'numeric' });
    const month = fixtureDate.toLocaleDateString('en-GB', { month: 'short' });
    return (
        <>
            {fixture && (
                <div className={'fixture-preview d-flex flex-row'}>
                    <div className="col-4 d-flex flex-column align-items-center justify-content-center">
                        <h4>{day}</h4>
                        <h6>{month}</h6>
                    </div>
                    <div className="col-8 d-flex flex-column align-items-center justify-content-center">
                        <h4>{fixture.against}</h4>
                        {fixtureDate > new Date() ? (
                            <h6>Upcoming</h6>
                        ) : (
                            <h6>{fixture.goals.length} - {fixture.againstScore}</h6>
                        )}
                        <button onClick={() => {
                            setFixEditing(fixture);
                            setIsEditing(true);
                        }}>Edit</button>
                    </div>
                </div>
            )}
        </>
        );
    }

export default FixturePreview;