import React from 'react';
import {useUser} from "../contexts/UserContext";
import {useTeam} from "../contexts/TeamContext";
import Select from "react-select";

function Report({fixture, isShowingReport, setIsShowingReport}) {
    const {user} = useUser();
    const {team} = useTeam();

    return (
        <div className={`report modal ${isShowingReport ? 'show' : ''}`} style={{display: isShowingReport ? 'block' : 'none'}} tabIndex="-1" >
            <div className="modal-dialog">
                <div className="modal-content" style={{
                    backgroundColor: team.theme.primaryColor,
                    border: "solid",
                    borderRadius: '20px',
                    borderWidth: "medium",
                    borderColor: team.theme.secondaryColor,
                    zIndex: 10
                }}>
                    <div className="modal-header">
                        <h5 className="modal-title">Match Report</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close" onClick={() => setIsShowingReport(false)}></button>
                    </div>
                    <div className="modal-body text-center">


                        <div className="scores row mb-2">
                            <h2 className={'text-start'}>Score</h2>
                            <div className="col-6">
                                <div className="d-flex align-items-center justify-content-center">
                                    <h1 className={'mx-2 mb-0'}>{fixture.goals ? fixture.goals.length : 0}</h1>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="d-flex align-items-center justify-content-center">
                                    <h1 className={'mx-2 mb-0'}>{fixture.againstScore}</h1>
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center">
                            <div className="col-6">
                                <h4>{team.teamName}</h4>
                            </div>
                            <div className="col-6">
                                <h4>{fixture.against}</h4>
                            </div>
                        </div>

                        {fixture.goals.length > 0 ? (
                            <hr/>
                        ) : (<> </>)}


                        <div className={'goals' + (fixture.goals.length <= 0 ? ' d-none' : '')}>
                            <h2 className={'text-start'}>Goals</h2>
                            {fixture.goals.length > 0 && (
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">Scorer</th>
                                        <th scope="col">Assister</th>
                                        <th scope="col">Minute</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {fixture.goals.map((goal, index) => (
                                        <tr key={index}>
                                            <td className={'align-middle text-center'}>
                                                <img src={goal.scorer.user.avatarUrl} alt="User Image"
                                                     style={{maxHeight: '50px'}}/>
                                                {goal.scorer.user.displayName}
                                            </td>
                                            <td className={'align-middle text-center'}>
                                                {goal.assister ? (
                                                    <>
                                                        <img src={goal.assister.user.avatarUrl} alt="User Image"
                                                             style={{maxHeight: '50px'}}/>
                                                        {goal.assister.user ? (goal.assister.user.displayName) : ('None')}
                                                    </>
                                                    ) : (
                                                    'None'
                                                )}
                                            </td>
                                            <td className={'align-middle text-center'}>{goal.minute}'</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            )}
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                                onClick={() => setIsShowingReport(false)}>Close
                        </button>
                    </div>
                </div>
            </div>
            {isShowingReport && <div className="modal-backdrop fade show" style={{zIndex: 5}}></div>}
        </div>
    );
}

export default Report;