import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import PlayerDisplay from '../components/PlayerDisplay';
import Papa from 'papaparse';
import { useTrail, animated } from 'react-spring';
import bitmojiImage from '../lib/images/bitmojiSelfie.png';
import {useTeam} from "../contexts/TeamContext";

function Squad() {
   const sortOptions = [
        { value: 'Goals', label: 'Goals' },
        { value: 'Assists', label: 'Assists' },
        { value: 'Appearances', label: 'Appearances' },
    ];
  const [squadData, setSquadData] = useState([]);
  const {team} = useTeam();
  const [isListView, setIsListView] = useState(false);
  const [selectedOption, setSelectedOption] = useState(sortOptions[0]);

    const [trail, setTrail] = useTrail(team.players.length || 0, index => ({
        transform: 'translateX(0%)',
        opacity: 1,
        delay: index * 100, // Adjust the multiplier to control the delay duration between items
        from: { transform: 'translateX(-100%)', opacity: 0 },
    }));
  const toggleView = () => {
    setIsListView(prevState => {
        if (!prevState) { // If we're about to show in list view
            const sortedSquadData = [...squadData].sort((a, b) => b[selectedOption.value] - a[selectedOption.value]);
            setSquadData(sortedSquadData);
        } else {
            const sortedSquadData = [...squadData].sort((a, b) => b.PlayerNumber - a.PlayerNumber);
            setSquadData(sortedSquadData);
        }

        return !prevState;
      });
  };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: '#6C3EA1',
            border: 'none',
            color: 'white',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'white',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#6C3EA1' : 'white',
            color: state.isSelected ? 'white' : 'black',
            '&:hover': {
                backgroundColor: 'lightpurple',
                color: 'white',
            }
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#6C3EA1',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'white',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: 'white',
            border: 'none'
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            backgroundColor: 'white',
        }),
        clearIndicator: (provided) => ({
            ...provided,
            color: 'white',
        })
    };


  useEffect(() => {
        if (squadData === null) return;
        const sortedSquadData = [...squadData].sort((a, b) => b[selectedOption.value] - a[selectedOption.value]);
        setSquadData(sortedSquadData);
    }, [selectedOption]);

  useEffect(()=>{
      setTrail(team.players.length || 0, index => ({
          transform: 'translateX(0%)',
          opacity: 1,
          delay: index * 100, // Adjust the multiplier to control the delay duration between items
          from: { transform: 'translateX(-100%)', opacity: 0 },
      }));
  }, [team])

  useEffect(() => {
    setTrail({
        immediate: true,
        to: {
            transform: 'translateX(-100%)',
            opacity: 0,
            width: '0%',
          }
    });
    if (isListView) {
        // Animate the expansion and translation from the left when list view is activated
        setTrail(index => ({
            reset: true,
            to: {
              transform: 'translateX(0%)',
              opacity: 1,
              width: '100%',
            },
            from: {
              transform: 'translateX(-100%)',
              opacity: 0,
              width: '0%',
            },
            
            delay: index * 100, // Applying staggered delay
          }));
        } else {
            setTrail({
                to: {
                    transform: 'translateX(0%)',
                    opacity: 1,
                },
                from: {
                    transform: 'translateX(-20%)', 
                    opacity: 0, 
                },
                reset: true,
            });
        }
    }, [isListView]);

  if (!team) {
      return <div>Loading Squad...</div>
  }
  return (
    <>
        <div className="squad p-2">
            <div className="d-flex justify-content-between p-2 align-items-center">
                <h1 className="title">Squad</h1>
                <i className="bi bi-sort-numeric-down title" onClick={toggleView}></i>
            </div>
            <div className="p-2" >
                {isListView ?
                    <Select
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={sortOptions}
                        styles={customStyles}
                    />
                    :
                    <></>
                }
            </div>

            <div className={`d-flex ${isListView ? 'flex-column' : 'flex-wrap justify-content-around'}`}>
                {team.players && trail.map((props, index) => (
                <animated.div className={`d-flex ${isListView ? '' : 'w-auto'}`}  key={index} style={props}>
                    <PlayerDisplay
                        id={team.players[index]._id}
                        name={team.players[index].user.displayName}
                        number={team.players[index].squadNumber}
                        bitmoji={team.players[index].user.avatarUrl}
                        goals={team.players[index].goals ? team.players[index].goals.length : 0}
                        assists={team.players[index].assists ? team.players[index].assists.length : 0}
                        mom={0}
                        apps={4}
                        showBanner={isListView}
                    />
                </animated.div>
                ))}
            </div>
        </div>
    </>
    
  );
}

export default Squad;
