import React, {useState} from 'react';
import FixtureForm from "./FixtureForm";
import axios from 'axios';
import {useTeam} from "../../contexts/TeamContext";

function EditFixture({fixture, isEditing, setIsEditing}) {
    const {team, setTeam} = useTeam();
    const [editedFixture, setEditedFixture] = useState({...fixture});
    const apiURL = process.env.REACT_APP_API_URL;
    const handleSubmit = async (e) => {
        e.preventDefault();

        const fixtureData = {
            teamId: team._id,
            against: editedFixture.against,
            date: editedFixture.date,
            goals: editedFixture.goals,
            againstScore: editedFixture.againstScore,
        };

        try {
            // Edit existing fixture
            const res = await axios.put(`${apiURL}/api/edit-fixture/${fixture._id}`, fixtureData);
            alert('Fixture updated successfully!');

            // Update the team context with the edited fixture
            setTeam((team) => ({
                ...team,
                fixtures: team.fixtures.map(f => f._id === fixture._id ? res.data.fixture : f),
            }));

            setIsEditing(false);
        } catch (error) {
            console.error('Error updating fixture:', error);
            alert('Failed to update fixture.');
        }
    };

    return (
        <FixtureForm isCreating={isEditing} setIsCreating={setIsEditing} handleSubmit={handleSubmit} fixture={editedFixture} setFixture={setEditedFixture}/>
    );
};

export default EditFixture;