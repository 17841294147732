import React, {useState} from 'react';
import {useTeam} from "../../contexts/TeamContext";
import PlayerDisplay from "../PlayerDisplay";
import {useUser} from "../../contexts/UserContext";
import axios from 'axios';

function TeamDisplay(props) {
    const {team, setTeam} = useTeam();
    const {user} = useUser();
    const [isEditing, setIsEditing] = useState(0);
    const [editedTeam, setEditedTeam] = useState({...team});
    const apiURL = process.env.REACT_APP_API_URL;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedTeam((prevData) => {
            if (name.startsWith('theme.')) {
                const themeKey = name.split('.')[1];
                return {
                    ...prevData,
                    theme: {
                        ...prevData.theme,
                        [themeKey]: value
                    }
                };
            }
            return {
                ...prevData,
                [name]: value
            };
        });

    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        axios.put(`${apiURL}/api/team/update/`, editedTeam)
            .then(response => {
            alert('Team details updated successfully');
            setTeam({
                ...editedTeam,
                fixtures: team.fixtures,
                players: team.players
            });
        })
            .catch(error => {
                console.error('Error updating team:', error);
                alert('Failed to update team details.');
            });
    }
    if (!editedTeam || !user) {
        return <div>Loading...</div>
    }
    return (

        <form onSubmit={handleSubmit}>
            <div className="name-details d-flex flex-column">
                <div className="form-group col-10 align-self-center d-flex flex-column justify-content-center">
                    <label htmlFor="teamName">Team Name</label>
                    {isEditing === 1 ? (
                        <input
                            className={'align-self-center'}
                            type="text"
                            name='teamName'
                            value={editedTeam.teamName}
                            onChange={handleChange}
                            onBlur={() => setIsEditing(0)}
                            autoFocus
                        />
                    ) : (
                        <div className="display-field align-self-center d-flex justify-content-center"
                             onClick={() => setIsEditing(1)}>
                            <h1 className={'align-self-center mb-0'}>{editedTeam.teamName}</h1>
                            <i className={'bi bi-pen align-self-center ms-2'}></i>
                        </div>
                    )}
                </div>

                <div className="d-flex flex-row justify-content-around">
                    <div className="form-group col-5">
                        <label htmlFor="shortName">Short Name</label>
                        {isEditing === 2 ? (
                            <input
                                type="text"
                                id="shortName"
                                name="shortName"
                                value={editedTeam.shortName}
                                onChange={handleChange}
                                className="form-control"
                                maxLength="5"
                                onBlur={() => setIsEditing(0)}
                                autoFocus
                            />
                        ) : (
                            <div
                                className="display-field align-self-center d-flex justify-content-center"
                                onClick={() => setIsEditing(2)}>
                                <h5 className={'align-self-center mb-0'}>{editedTeam.shortName}</h5>
                                <i className={'bi bi-pen align-self-center ms-2'}></i>
                            </div>
                        )}

                    </div>

                    <div className="form-group col-5">
                        <label htmlFor="domainName">Domain Name</label>
                        {isEditing === 3 ? (
                            <input
                                type="text"
                                id="domainName"
                                name="domainName"
                                value={editedTeam.domainName}
                                onChange={handleChange}
                                className="form-control"
                                maxLength="20"
                                onBlur={() => setIsEditing(0)}
                                autoFocus
                            />
                        ) : (
                            <div
                                className="display-field align-self-center d-flex justify-content-center"
                                onClick={() => setIsEditing(3)}>
                                <h5 className={'align-self-center mb-0'}>{editedTeam.domainName}</h5>
                                <i className={'bi bi-pen align-self-center ms-2'}></i>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <br/>

            <div className="theme-picker d-flex flex-row justify-content-around">
                <div className="color-selection col-5 ">
                    <div className="form-group">
                        <label htmlFor="primaryColor">Primary Color</label>
                        <input
                            type="color"
                            id="primaryColor"
                            name="theme.primaryColor"
                            value={editedTeam.theme.primaryColor}
                            onChange={handleChange}
                            className="form-control"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="secondaryColor">Secondary Color</label>
                        <input
                            type="color"
                            id="secondaryColor"
                            name="theme.secondaryColor"
                            value={editedTeam.theme.secondaryColor}
                            onChange={handleChange}
                            className="form-control"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="textColor">Text Color</label>
                        <input
                            type="color"
                            id="textColor"
                            name="theme.textColor"
                            value={editedTeam.theme.textColor}
                            onChange={handleChange}
                            className="form-control"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="backgroundColor">Background Color</label>
                        <input
                            type="color"
                            id="backgroundColor"
                            name="theme.backgroundColor"
                            value={editedTeam.theme.backgroundColor}
                            onChange={handleChange}
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="col-5 align-self-center d-flex justify-content-center" style={{background: editedTeam.theme.backgroundColor, borderRadius: '10px'}}>

                    <PlayerDisplay
                        id = {1}
                        name = {user.displayName}
                        number = {user.player.squadNumber}
                        bitmoji = {user.avatarUrl}
                        goals ={1}
                        assists ={1}
                        mom ={1}
                        apps ={1}
                        showBanner ={false}
                        editedTheme={editedTeam}
                    />
                </div>
            </div>

            <div className="d-flex justify-content-end">
                <button type="submit" className="btn btn-primary m-2">Save changes</button>
            </div>

        </form>
    );
}

export default TeamDisplay;