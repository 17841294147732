import React from 'react';

function Footer(props) {
    return (
        <>
            <div className="footer d-flex justify-content-end border-top p-2">
                Powered by <a href={'https://jackmarchant1.github.io/Tactico-Landing/'} style={{textDecoration: "none", color: "white", fontWeight: "bold"}}>&nbsp;Tactico</a>
            </div>
        </>
    );
}

export default Footer;