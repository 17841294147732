import React from 'react';
import {useUser} from "../contexts/UserContext";
import Navbar from "./Navbar";
import {useTeam} from "../contexts/TeamContext";
import {HashLoader} from "react-spinners";

const Profile = () => {
    const { user, fullBodyBitmojiIds } = useUser();
    const {team} = useTeam();
    const apiURL = process.env.REACT_APP_API_URL;

    const getRandomBitmojiId = () => {
        const keys = Object.keys(fullBodyBitmojiIds);
        const randomKey = keys[Math.floor(Math.random() * keys.length)];
        return fullBodyBitmojiIds[randomKey];
    };

    const positionMap = {
        0: 'Goalkeeper',
        1: 'Defender',
        2: 'Forward'
    };

    if (!user || !team) {
        return (
            <div className="d-flex justify-content-center align-items-center flex-column" style={{height: '100vh'}}>
                <HashLoader
                    color="#ffffff"
                    size={50}
                />
                <br/>
                Loading profile...
            </div>
        )
    }

    return (
        <>
            <Navbar></Navbar>
            <div className={"profile p-2"}>
                <h1>Profile</h1>
                <div className="profile-card d-flex m-1 p-2" style={{
                    backgroundColor: team.theme.primaryColor,
                    border: "solid",
                    borderRadius: '20px',
                    borderWidth: "medium",
                    borderColor: team.theme.secondaryColor
                }}>
                    <div className="bitmojiContainer col-6">
                        <img src={`https://sdk.bitmoji.com/me/sticker/${user.bitmojiAvatarId}/${getRandomBitmojiId()}`} alt=""
                             className={"mw-100"}/>
                    </div>
                    <div className="infoContainer col-6 d-flex flex-column align-items-center justify-content-center">
                        <div className="user-info text-center">
                            <h2>{user.displayName}</h2>
                            <h6>{positionMap[user.player.position]}</h6>
                        </div>


                        <div className="stats d-flex flex-row text-center w-100">
                            <div className="d-flex flex-column col-4">
                                <p className={"mb-0"}>{user.player.goals.length}</p>
                                <p className={"mb-0"}>Goals</p>
                            </div>
                            <div className="d-flex flex-column col-4">
                                <p className={"mb-0"}>{user.player.assists.length}</p>
                                <p className={"mb-0"}>Assists</p>
                            </div>
                            <div className="d-flex flex-column col-4 align-items-center justify-content-center">
                                <p className={"mb-0"}>4</p> {/* TODO: sort out appearances */}
                                <p className={"mb-0"}>Apps</p>
                            </div>
                        </div>
                    </div>
                </div>

                <a href={`${apiURL}/logout`}>Logout</a>
            </div>
        </>

    );
};

export default Profile;
