import React from 'react';
import Fixtures from "./sections/Fixtures";
import Squad from "./sections/Squad";
import Reports from "./sections/Reports";
import Navbar from "./sections/Navbar";
import Footer from "./sections/Footer";
import {useTeam} from "./contexts/TeamContext";
import PredictionLeaderboard from "./sections/PredictionLeaderboard";
import {HashLoader} from "react-spinners";

function Homepage(props) {
    const {team} = useTeam();
    if (team === null) {
        return (
            <div className="d-flex justify-content-center align-items-center flex-column" style={{height: '100vh'}}>
                <HashLoader
                    color="#ffffff"
                    size={50}
                />
                <br />
                Loading team data...
            </div>
        )
    }
    return (
        <div className="d-flex flex-column min-vh-100">

            <Navbar />

            <div className="flex-grow-1">
                <Fixtures />

                <Squad />

                <PredictionLeaderboard />
            </div>



            <Footer />
        </div>
    );
}

export default Homepage;