import React from 'react';

function PrivacyPolicy(props) {
    return (
        <div className="container mt-4">
            <h1>Privacy Policy</h1>
            <p><strong>Effective Date:</strong> [Insert Date]</p>

            <p>
                At <strong>Tactico</strong> (“we,” “our,” “us”), we value your privacy and are committed to protecting
                the personal
                information you share with us. This Privacy Policy explains how we collect, use, and safeguard your
                information when
                you use our web application. Please read this policy carefully to understand our practices.
            </p>

            <h3>1. Information We Collect</h3>
            <p>
                We use Snapchat as our primary authentication method to provide access to our app. When you log in using
                Snapchat,
                we collect the following information:
            </p>
            <ul>
                <li><strong>Display Name</strong>: Your display name as provided by Snapchat.</li>
                <li><strong>Bitmoji Avatar</strong>: Your Bitmoji avatar as provided by Snapchat.</li>
            </ul>
            <p>
                We do not collect any additional personal information beyond what is required for Snapchat
                authentication.
            </p>

            <h3>2. How We Use Your Information</h3>
            <p>
                The information we collect from Snapchat is used solely within the app to provide you with a
                personalized experience.
                Specifically:
            </p>
            <ul>
                <li><strong>Display Name</strong>: Used to identify you within Tactico.</li>
                <li><strong>Bitmoji Avatar</strong>: Used as your avatar or profile picture within the app.</li>
            </ul>
            <p>We do not use, share, or sell your display name or Bitmoji avatar outside of the app for any purpose.</p>

            <h3>3. Information Sharing and Disclosure</h3>
            <p>
                We do not share, sell, or rent your personal information to third parties. The information collected via
                Snapchat
                authentication is used exclusively within Tactico.
            </p>
            <p>We may disclose your information only in the following circumstances:</p>
            <ul>
                <li>If required by law, regulation, or legal process.</li>
                <li>To protect the rights, property, or safety of Tactico, our users, or others.</li>
            </ul>

            <h3>4. Data Security</h3>
            <p>
                We take the security of your personal information seriously. We implement industry-standard security
                measures to
                protect the information we collect. However, please note that no method of transmission over the
                internet or method
                of electronic storage is 100% secure, and we cannot guarantee its absolute security.
            </p>

            <h3>5. Third-Party Services</h3>
            <p>
                Tactico uses Snapchat’s developer kit for authentication. By using Snapchat to log in, you are also
                subject to
                Snapchat’s privacy policy, which can be found here:
                <a href="https://snap.com/en-US/privacy/privacy-policy" target="_blank" rel="noopener noreferrer">Snapchat
                    Privacy Policy</a>.
            </p>

            <h3>6. Children’s Privacy</h3>
            <p>
                Tactico is not intended for use by children under the age of 13, and we do not knowingly collect
                personal
                information from children under this age. If you believe we have collected such information, please
                contact us at
                me@jackmarchant.dev, and we will promptly delete the information.
            </p>

            <h3>7. Changes to This Privacy Policy</h3>
            <p>
                We may update this Privacy Policy from time to time. When we do, we will revise the "Effective Date" at
                the top of
                this page. We encourage you to review this policy periodically to stay informed about how we are
                protecting your
                information.
            </p>

            <h3>8. Contact Us</h3>
            <p>
                If you have any questions about this Privacy Policy or our practices, please contact us at:
            </p>
            <p>
                Tactico<br/>
                me@jackmarchant.dev<br/>
            </p>
        </div>
    );
}

export default PrivacyPolicy;