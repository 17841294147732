import React, {useEffect, useState} from 'react';
import axios from 'axios';
function PredictionLeaderboard(props) {
    const [users, setUsers] = useState([]);
    const apiURL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        axios.get(`${apiURL}/api/users`)
            .then((res) => {
                setUsers(res.data);
                console.log(res.data); // This will log the updated users
            })
            .catch((e) => { alert('Error fetching users'); });
    }, []);

    return (
        <div className="leaderboard p-2" style={{maxHeight: '400px', overflow: 'scroll'}}>
            <h1>Prediction Leaderboard</h1>
            <table className="table">
                <thead>
                <tr>
                    <th scope="col-2">Rank</th>
                    <th scope="col-8">User</th>
                    <th scope="col-2">Points</th>
                </tr>
                </thead>
                <tbody>
                {users.map((user, index) => (
                    <tr key={index}>
                        <th scope="row" className={'align-middle text-center'}>{index+1}</th>
                        <td className={'align-middle text-center'}>
                            <img src={user.avatarUrl} alt="User Image" style={{maxHeight: '50px'}}/>
                            {user.displayName}
                        </td>
                        <td className={'align-middle text-center'}>0</td>{/*TODO: add in prediction logic*/}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default PredictionLeaderboard;