import React, {useState} from 'react';
import FixturePreview from "./FixturePreview";
import CreateFixture from "./CreateFixture";
import {useTeam} from "../../contexts/TeamContext";
import EditFixture from "./EditFixture";

function FixtureList(props) {
    const [isCreating, setIsCreating] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [fixEditing, setFixEditing] = useState(null);
    const {team} = useTeam();
    console.log('team is ' + JSON.stringify(team));
    return (
        <div className={'p-2'}>
            {isCreating && (<CreateFixture isCreating={isCreating} setIsCreating={setIsCreating} />)}
            {isEditing && (<EditFixture isEditing={isEditing} setIsEditing = {setIsEditing} fixture={fixEditing} />)}
            <button onClick={() => {
                setIsCreating(!isCreating);
            }}> Create Fixture </button>
            {team.fixtures.sort((a, b) => new Date(a.date) - new Date(b.date)).map((fixture, index) => (
                <>
                    <FixturePreview key={index} fixture={fixture} setFixEditing={setFixEditing} setIsEditing={setIsEditing}/>
                    <hr />
                </>

            ))}
        </div>
    );
}

export default FixtureList;