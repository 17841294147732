import React, {useState, useEffect} from 'react';
import Navbar from "./Navbar";
import {useTeam} from "../contexts/TeamContext";
import {useUser} from "../contexts/UserContext";
import axios from 'axios';
import {useNavigate} from "react-router-dom";

function CreatePlayer(props) {
    const {team} = useTeam();
    const {user, setUser} = useUser();
    const navigate = useNavigate();
    const apiURL = process.env.REACT_APP_API_URL;

    const [name, setName] = useState('Display Name');
    const [squadNumber, setSquadNumber] = useState('0');
    const [position, setPosition] = useState(0);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const playerData = {
            squadNumber,
            position,
            user: user,
            team: team._id
        };

        try {
            await axios.post(`${apiURL}/api/create-player`, playerData);
            console.log('Successfully created');
            alert('Successfully created player');
            navigate('/');
        } catch (error) {
            console.error('Error creating player profile:', error);
            // Handle error (e.g., show an error message)
        }
    };

    if (!user || !team) {
        return <div>Loading...</div>
    }

    return (
        <>
            <Navbar></Navbar>
            <div className={"profile p-2"}>
                <h1>Create player profile</h1>
                <div className="profile-card d-flex m-1 p-2" style={{
                    backgroundColor: team.theme.primaryColor,
                    border: "solid",
                    borderRadius: '20px',
                    borderWidth: "medium",
                    borderColor: team.theme.secondaryColor
                }}>
                    <div className="bitmojiContainer col-6 d-flex align-items-center justify-content-center">
                        <img src={user.avatarUrl} alt=""
                             className={"mw-100"}/>
                    </div>
                    <div className="infoContainer col-6 d-flex flex-column align-items-center justify-content-center">
                        <form onSubmit={handleSubmit} className="m-1 p-2">
                            <div className="form-group">
                                <label htmlFor="name">Display Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    className="form-control"
                                    value={user.displayName}
                                    onChange={(e) => setUser({...user, displayName: e.target.value})}
                                    maxLength="10"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="squadNumber">Squad Number</label>
                                <input
                                    type="number"
                                    id="squadNumber"
                                    className="form-control"
                                    value={squadNumber}
                                    onChange={(e) => setSquadNumber(e.target.value)}
                                    min="0"
                                    max="99"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="position">Position</label>
                                <select
                                    id="position"
                                    className="form-control"
                                    value={position}
                                    onChange={(e) => setPosition(e.target.value)}
                                    required
                                >
                                    <option value="0">🧤Goalkeeper</option>
                                    <option value="1">🧱Defender</option>
                                    <option value="2">🥅Forward</option>
                                </select>
                            </div>
                            <button type="submit" className="btn btn-primary mt-2">
                                Create Profile
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
        ;
}

export default CreatePlayer;