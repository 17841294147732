import React, {useState} from 'react';
import Navbar from "./Navbar";
import {useTeam} from "../contexts/TeamContext";

function Login(props) {
    const {team} = useTeam();
    const apiURL = process.env.REACT_APP_API_URL;
    const [pinCode, setPinCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handlePinChange = (e) => setPinCode(e.target.value);

    const handleVerifyPin = async (e) => {
        e.preventDefault();
        try {
            const host = window.location.host;
            // const domainName = host.split('.')[0]; //TODO: Sort out domain name

            const domainName = 'wanfc';

            const response = await fetch(apiURL + '/api/verify-pin', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ domainName, pinCode }),
            });
            const result = await response.json();
            if (result.success) {
                setErrorMessage('');
                window.location.href = `${apiURL}/auth/snapchat?role=player`;
            } else {
                setErrorMessage(result.message);
            }
        } catch (error) {
            setErrorMessage('An error occurred verifying the pin code. Please try again.');
        }
    };

    const handleFanLogin = () => {
        window.location.href = `${apiURL}/auth/snapchat?role=fan`;
    };

    return (
        <>
            <Navbar/>
            <div className={"login p-2"}>
                <h1 className="title">Login</h1>
                <div className="player-login mb-4">
                    <h4>Player login</h4>
                    <p>To log in as a player of the team, please enter the team's pincode below and tap login. You will be securely redirected to Snapchat in order to link your accounts.</p>
                    <form onSubmit={handleVerifyPin}>
                        <div className="mb-3">
                            <input
                                type="password"
                                className="form-control"
                                value={pinCode}
                                onChange={handlePinChange}
                                required
                                placeholder="Pin Code"
                            />
                        </div>
                        <div className="submit-buttons w-100">
                            <button type="submit" className="btn w-100" style={team ? {
                                backgroundColor: team.theme.primaryColor,
                                color: team.theme.textColor
                            } : {backgroundColor: "blue", color: "white"}}>Log in with Snapchat <br/> (Recommended)
                            </button>
                        </div>
                    </form>
                    {errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>}
                </div>

                <hr />

                <div className="fan-login">
                    <h4>Fan login</h4>
                    <p>If you are not a player for {team ? team.teamName : 'the team'}, but still want to participate in fantasy, create your
                        account and login here.</p>
                    <button className="btn w-100" onClick={handleFanLogin} style={team ? {
                        backgroundColor: team.theme.primaryColor,
                        color: team.theme.textColor
                    } : {backgroundColor: "blue", color: "white"}}>Log in with Snapchat <br/> (Recommended)
                    </button>
                </div>
            </div>
        </>
    );
}

export default Login;