import React, {useState} from 'react';
import {useTeam} from "../contexts/TeamContext";
import Select from "react-select";
import axios from "axios";
import {useUser} from "../contexts/UserContext";

function MatchPrediction({fixture, isPredicting, setIsPredicting}) {
    const {user} = useUser();
    const {team} = useTeam();
    const apiURL = process.env.REACT_APP_API_URL;
    const [prediction, setPrediction] = useState({
        homeScore: 0,
        againstScore: 0,
        POTM: null,
        topScorer: null,
        topAssister: null,
        firstScorer: null
    });

    const handleSubmit = async () => {
        try {
            const res = await axios.post(`${apiURL}/api/create-prediction`, {
                ...prediction,
                user: user._id,
                fixture: fixture._id,
                POTM: prediction.POTM._id,
                topScorer: prediction.topScorer._id,
                topAssister: prediction.topAssister._id,
                firstScorer: prediction.firstScorer._id
            });
            alert('Prediction created successfully!');
        } catch (error) {
            console.error('Error creating prediction:', error, {
                ...prediction,
                user: user,
                fixture: fixture,
                POTM: prediction.POTM,
                topScorer: prediction.topScorer,
                topAssister: prediction.topAssister,
                firstScorer: prediction.firstScorer
            });
            alert('Error creating prediction. Only authenticated users can submit predictions.');
        }

    };

    return (
        <div className={`prediction modal ${isPredicting ? 'show' : ''}`} style={{display: isPredicting ? 'block' : 'none'}}
             tabIndex="-1">
            <div className="modal-dialog">
                <div className="modal-content" style={{
                    backgroundColor: team.theme.primaryColor,
                    border: "solid",
                    borderRadius: '20px',
                    borderWidth: "medium",
                    borderColor: team.theme.secondaryColor,
                    zIndex: 10
                }}>
                    <div className="modal-header">
                        <h5 className="modal-title">Make Predictions</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close" onClick={() => setIsPredicting(false)}></button>
                    </div>
                    <div className="modal-body text-center">

                        <h2 className={'text-start'}>Score Prediction</h2>
                        <div className="scores row mb-2">
                            <div className="col-6">
                                <div className="d-flex align-items-center justify-content-center">
                                    {(prediction.homeScore > 0) && (
                                        <i className="bi bi-dash-lg" onClick={() => {
                                            setPrediction({...prediction, homeScore: (prediction.homeScore - 1)})
                                        }}></i>
                                    )}
                                    <h1 className={'mx-2 mb-0'}>{prediction.homeScore}</h1>
                                    <i className="bi bi-plus-lg" onClick={() => {
                                        setPrediction({...prediction, homeScore: (prediction.homeScore + 1)})
                                    }}></i>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="d-flex align-items-center justify-content-center">
                                    {(prediction.againstScore > 0) && (
                                        <i className="bi bi-dash-lg" onClick={() => {
                                            setPrediction({...prediction, againstScore: (prediction.againstScore - 1)})
                                        }}></i>
                                    )}

                                    <h1 className={'mx-2 mb-0'}>{prediction.againstScore}</h1>
                                    <i className="bi bi-plus-lg" onClick={() => {
                                        setPrediction({...prediction, againstScore: (prediction.againstScore + 1)})
                                    }}></i>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-6">
                                <h4>{team.teamName}</h4>
                            </div>
                            <div className="col-6">
                                <h4>{fixture.against}</h4>
                            </div>
                        </div>

                        <hr/>

                        <h2 className={'text-start'}>Player Predictions</h2>
                        <div className="row align-items-center">
                            <div className="col-6">
                                <label htmlFor={'firstScorerSelect'}>First Goal Scorer:</label>
                            </div>
                            <div className="col-6">
                                <label htmlFor={'topScorerSelect'}>Most Goals:</label>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-6">
                                <Select
                                    id={'firstScorerSelect'}
                                    options={team.players}
                                    formatOptionLabel={player => (
                                        <div className="player-option">
                                            <img src={player.user.avatarUrl} alt="player-image"
                                                 style={{width: '50px', height: '50px', marginRight: '10px'}}/>
                                        </div>
                                    )}
                                    isSearchable={false}
                                    onChange={(player) => setPrediction({...prediction, firstScorer: player})}
                                    placeholder="Select Player"
                                />
                            </div>
                            <div className="col-6">
                                <Select
                                    id={'topScorerSelect'}
                                    options={team.players}
                                    formatOptionLabel={player => (
                                        <div className="player-option">
                                            <img src={player.user.avatarUrl} alt="player-image"
                                                 style={{width: '50px', height: '50px', marginRight: '10px'}}/>
                                        </div>
                                    )}
                                    isSearchable={false}
                                    onChange={(player) => setPrediction({...prediction, topScorer: player})}
                                    placeholder="Select Player"
                                />
                            </div>
                        </div>

                        <div className="row align-items-center">
                            <div className="col-6">
                                <label htmlFor={'topAssisterSelect'}>Most Assists:</label>
                            </div>
                            <div className="col-6">
                                <label htmlFor={'POTMSelect'}>POTM:</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Select
                                    id={'topAssisterSelect'}
                                    options={team.players}
                                    formatOptionLabel={player => (
                                        <div className="player-option">
                                            <img src={player.user.avatarUrl} alt="player-image"
                                                 style={{width: '50px', height: '50px', marginRight: '10px'}}/>
                                        </div>
                                    )}
                                    isSearchable={false}
                                    onChange={(player) => setPrediction({...prediction, topAssister: player})}
                                    placeholder="Select Player"
                                />
                            </div>
                            <div className="col-6">
                                <Select
                                    id={'POTMSelect'}
                                    options={team.players}
                                    formatOptionLabel={player => (
                                        <div className="player-option">
                                            <img src={player.user.avatarUrl} alt="player-image"
                                                 style={{width: '50px', height: '50px', marginRight: '10px'}}/>
                                        </div>
                                    )}
                                    isSearchable={false}
                                    onChange={(player) => setPrediction({...prediction, POTM: player})}
                                    placeholder="Select Player"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                                onClick={() => setIsPredicting(false)}>Close
                        </button>
                        <button type="button" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </div>
            {isPredicting && <div className="modal-backdrop fade show" style={{zIndex: 5}}></div>}
        </div>

    )
        ;
}

export default MatchPrediction;