import React, {useEffect, useState} from 'react';
import FixturePreview from "./FixturePreview";
import {useTeam} from "../contexts/TeamContext";

const Carousel = () => {
    const { team } = useTeam();
    const [sortedFixtures, setSortedFixtures] = useState([]);
    const [initialIndex, setInitialIndex] = useState(0);

    useEffect(() => {
        if (team && team.fixtures) {
            // Sort the fixtures by date
            const sorted = team.fixtures.sort((a, b) => new Date(a.date) - new Date(b.date));
            setSortedFixtures(sorted);

            // Get today's date
            const today = new Date();

            // Find the index of the next upcoming fixture
            const nextFixtureIndex = sorted.findIndex(fixture => new Date(fixture.date) > today);

            // If no future fixtures are found, set the first fixture as the initial slide
            setInitialIndex(nextFixtureIndex !== -1 ? nextFixtureIndex : 0);
        }
    }, [team]);

    return (
        <div id="carouselExample" className="carousel slide">
            <div className="carousel-indicators">
                {sortedFixtures.map((_, index) => (
                    <button
                        key={index}
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to={index}
                        className={index === initialIndex ? "active" : ""}
                        aria-current={index === initialIndex ? "true" : undefined}
                        aria-label={`Fixture ${index + 1}`}
                    ></button>
                ))}
            </div>
            <div className="carousel-inner">
                {sortedFixtures.map((fixture, index) => (
                    <div key={index} className={'carousel-item' + (index === initialIndex ? ' active' : '')}>
                        <FixturePreview fixture={fixture}/>
                    </div>
                ))}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample"
                    data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExample"
                    data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
}

export default Carousel;
