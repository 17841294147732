import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, Routes, Route } from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import Homepage from "./Homepage";
import { TeamProvider } from "./contexts/TeamContext";
import { UserProvider } from "./contexts/UserContext";
import Login from "./sections/Login";
import Profile from "./sections/Profile";
import AdminPanel from "./sections/AdminPanel";
import CreatePlayer from "./sections/CreatePlayer";
import MatchPrediction from "./sections/MatchPrediction";
import PrivacyPolicy from "./sections/PrivacyPolicy";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <TeamProvider>
          <UserProvider>
              <HashRouter>
                  <Routes>
                      <Route path="/login" element={<Login />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/admin" element={<AdminPanel />} />
                      <Route path="/predict" element={<MatchPrediction />} />
                      <Route path="/create-player" element={<CreatePlayer />} />
                      <Route path='/privacy' element={<PrivacyPolicy />} />
                      <Route path="*" element={<Homepage/>} />
                  </Routes>
              </HashRouter>
          </UserProvider>
      </TeamProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
