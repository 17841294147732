import React from 'react';
import { useTeam } from "../contexts/TeamContext";
import { useUser } from "../contexts/UserContext";

function Navbar(props) {
    const {team} = useTeam();
    const {user} = useUser();
    if (team === null) {
        return(
            <>
                <div>
                    {user === null ? (
                        <a href="/#/login" className="nav-link">Login</a>
                    ) : (
                        <p>{user.displayName} <i className="bi bi-person-fill"></i> </p>
                    )}
                </div>
            </>
        )
    }
    return (
        <nav className="navbar border-bottom mb-2" style={{backgroundColor: team.theme.primaryColor}}>
            <div className="container-fluid">
                <a className="navbar-brand" href="/"
                   style={{color: team.theme.secondaryColor}}>{team.shortName}</a>
                <div className="navbar">
                    {user === null ? (
                        <a href="/#/login" className="nav-link">Login</a>
                    ) : (
                        <>
                            {user.isAdmin && (
                                <>
                                    <a href="/#/admin" className="nav-link mx-2">Admin</a>
                                </>

                            )}
                            <a href="/#/profile" className={"nav-link"}>{user.displayName}<i
                                className="bi bi-person-fill mx-1"></i></a>
                        </>

                    )}
                </div>
            </div>
        </nav>
    )
}

export default Navbar;