import React, {createContext, useState, useEffect, useContext} from 'react';

const TeamContext = createContext();

export const TeamProvider = ({ children }) => {
    const [team, setTeam] = useState(null);
    const apiURL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchTeam = async () => {
            const host = window.location.host;
            // const subdomain = host.split('.')[0]; //TODO: Sort out subdomain
            const subdomain = 'wanfc';
            fetch(`${apiURL}/api/team?domainName=${subdomain}`, {
                credentials: 'include'
            })
                .then(response => response.json())
                .then(data => setTeam(data))
                .catch((error) => {
                    console.error('Error fetching team ' + error)
                });
        };
        fetchTeam();
    }, []);

    return (
        <TeamContext.Provider value={{team, setTeam}}>
            {children}
        </TeamContext.Provider>
    );
};

export const useTeam = () => useContext(TeamContext);
