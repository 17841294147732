import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useTeam} from "../../contexts/TeamContext";
import Select from 'react-select';
import FixtureForm from "./FixtureForm";

function CreateFixture({isCreating, setIsCreating}) {
    const [against, setAgainst] = useState('');
    const [date, setDate] = useState('');
    const [goals, setGoals] = useState([]);
    const [againstScore, setAgainstScore] = useState(0);
    const [isPastFixture, setIsPastFixture] = useState(false);
    const [isAddingGoal, setIsAddingGoal] = useState(false);
    const [newGoal, setNewGoal] = useState({scorer: {}, assister: {}, minute: null});
    const {team, setTeam} = useTeam();
    const apiURL = process.env.REACT_APP_API_URL;


    const handleSubmit = async (e) => {
        e.preventDefault();

        const fixtureData = {
            teamId: team._id,
            against,
            date,
            goals: isPastFixture ? goals : [],
            againstScore: isPastFixture ? againstScore : -1,
        };

        try {
            const res = await axios.post(`${apiURL}/api/create-fixture`, fixtureData);
            alert('Fixture created successfully!');
            setTeam((team) => ({ ...team, fixtures: [...team.fixtures, res.data.fixture] })); //add fixture to team
            setIsCreating(false);
        } catch (error) {
            console.error('Error creating fixture:', error);
            alert('Failed to create fixture.');
        }
    };

    return (
        <FixtureForm isCreating={isCreating} setIsCreating={setIsCreating} handleSubmit={handleSubmit} fixture = {null}/>
    );
}

export default CreateFixture;
