import React, {useState} from 'react';
import MatchPrediction from "../sections/MatchPrediction";
import Report from "../sections/Report";

function FixturePreview({fixture}) {
    const date = new Date(fixture.date);
    const today = new Date();
    const [isPredicting, setIsPredicting] = useState(false);
    const [isShowingReport, setIsShowingReport] = useState(false);
    function formatDate(fixDate)  {
        const day = date.getDate();
        const month = date.toLocaleDateString('en-GB', { month: 'long' });
        const year = date.getFullYear();

        // Determine the correct suffix for the day
        let daySuffix;
        if (day % 10 === 1 && day !== 11) {
            daySuffix = 'st';
        } else if (day % 10 === 2 && day !== 12) {
            daySuffix = 'nd';
        } else if (day % 10 === 3 && day !== 13) {
            daySuffix = 'rd';
        } else {
            daySuffix = 'th';
        }

        return `${day}${daySuffix} ${month} ${year}`;
    }
    return (
        <>
            {isPredicting && (<MatchPrediction fixture={fixture} isPredicting={isPredicting} setIsPredicting={setIsPredicting}/>)}
            {isShowingReport && (<Report fixture={fixture} isShowingReport={isShowingReport} setIsShowingReport={setIsShowingReport} />)}
            <div className="d-block w-100" style={{height: '175px'}}>
                <div className="carousel-caption">
                    <h5>{fixture.against}</h5>
                    <p>{formatDate(fixture.date)}</p>
                    {date < today ? ( //display appropriate action depending on past/future fixture
                        <a onClick={() => {
                            setIsShowingReport(true);
                        }}
                           className='link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover'><i
                            className="bi bi-file-text"></i> View Report</a>
                    ) : (
                        <a onClick={() => {
                            setIsPredicting(true);
                        }}
                           className='link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover'><i
                            className="bi bi-ui-checks"></i> Make Predictions</a>
                    )}

                </div>
            </div>
        </>

    );
}

export default FixturePreview;