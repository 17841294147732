import React, {useState} from 'react';
import Navbar from "./Navbar";
import {useTeam} from "../contexts/TeamContext";
import {useUser} from "../contexts/UserContext";
import TeamDisplay from "../components/AdminPanelComponents/TeamDisplay";
import FixtureList from "../components/AdminPanelComponents/FixtureList";
import CreateFixture from "../components/AdminPanelComponents/CreateFixture";

function AdminPanel(props) {
    const {team} = useTeam();
    const user = useUser();

    const [isVisible, setIsVisible] = useState(false);



    if (!user || !team) {
        return <div>Loading...</div>
    }
    return (
        <>
            <Navbar />

            <div className='admin p-2'>

                <h1>Admin panel</h1>

                <div className="edit-squad">
                    <h4>Edit Squad</h4>
                    <div className="m-1 p-2" style={{
                        backgroundColor: team.theme.primaryColor,
                        border: "solid",
                        borderRadius: '20px',
                        borderWidth: "medium",
                        borderColor: team.theme.secondaryColor
                    }}>
                        <p>Team members can add themselves to the squad by authenticating through Snapchat and creating
                            their player profile. Authentication to the team requires the team pin which can be found
                            below</p>
                        <div className="team-pin d-flex flex-row justify-content-center m-2">
                            <p className={'mb-0'}>Team pin: {isVisible ? (team.pinCode) : ('****')}</p>
                            <button className={'ms-2'} onClick={() => setIsVisible(!isVisible)}
                                    style={{border: 'none', background: 'none', color: team.theme.textColor}}>
                                <i className={isVisible ? 'bi bi-eye-slash' : 'bi bi-eye'}></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="edit-team-details">
                    <h4>Edit team details</h4>
                    <div className="m-1 p-1" style={{
                        backgroundColor: team.theme.primaryColor,
                        border: "solid",
                        borderRadius: '20px',
                        borderWidth: "medium",
                        borderColor: team.theme.secondaryColor
                    }}>
                        <TeamDisplay />
                    </div>
                </div>

                <div className="edit-fixtures">
                    <h4>Edit fixtures</h4>
                    <div className="m-1 p-1" style={{
                        backgroundColor: team.theme.primaryColor,
                        border: "solid",
                        borderRadius: '20px',
                        borderWidth: "medium",
                        borderColor: team.theme.secondaryColor,
                        maxHeight: '350px',
                        overflow: 'scroll'
                    }}>
                        <FixtureList/>
                    </div>
                </div>

            </div>
        </>

            );
            }

export default AdminPanel;